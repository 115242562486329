import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Button, Content, CTABox, Spacer } from 'components';
export const _frontmatter = {
  "title": "Cookie Policy"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Content mdxType="Content">
	<Spacer height={100} responsive={{
        md: 80,
        sm: 60
      }} mdxType="Spacer" />
	<h1>Cookie Policy</h1>
	<p>We like cookies, do you?</p>
    </Content>
    <Spacer height={60} responsive={{
      md: 40,
      sm: 30
    }} mdxType="Spacer" />
    <CTABox color="banana99" description="Still loosing track of your notes? Bring new tab and you ready to jot down in one vault. Online and offline. MadNotes is always for you on every device." heading="Boost your efficiency!" mdxType="CTABox">
	<Button arrow="right" color="berry99" to="https://use.madnotes.app" type="plain" mdxType="Button">
		Try now
	</Button>
	<Button color="berry99" to="/#pricing" type="ghost" gtag={['event', 'click', {
        event_label: 'Pricing'
      }]} mdxType="Button">
		See Pricing
	</Button>
    </CTABox>
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      